import React from 'react'
import system from '@rebass/components'
import { Box, Flex, Text } from 'rebass'
import { colors, fonts } from 'theme'
import { ContentContainer, Link } from 'components'
import facebook from '../../images/facebook.svg'
import linkedin from '../../images/linkedin.svg'

const Separator = system(
  {
    extend: Box,
    bg: colors.lightTeal,
    height: '1px',
    width: '100%',
    mb: '20px',
  },
  'color',
  'space',
  'height',
  'width',
)

const Container = system(
  {
    extend: Flex,
    width: '100%',
    justifyContent: 'space-between',
    mb: '20px',
    flexWrap: 'wrap',
  },
  'width',
  'space',
)

const MenuContainer = system(
  {
    extend: Box,
    minWidth: '200px',
    mx: '30px',
    mt: '20px',
  },
  'minWidth',
  'space',
)

const Title = system(
  {
    extend: Text,
    color: colors.white,
    mb: '20px',
    fontWeight: '500',
    fontFamily: fonts.main,
    fontSize: '18px',
    lineHeight: '1.33',
  },
  {
    textTransform: 'uppercase',
    letterSpacing: '0.2em',
    a: {
      display: 'block',
      lineHeight: '1.33',
      textDecoration: 'none',
      color: colors.white,
      fontWeight: '500',
    },
    'a:hover': {
      color: colors.lightTeal,
    },
  },
)

const Menu = system(
  {
    extend: Box,
    as: 'ul',
    m: 0,
  },
  'space',
  'maxWidth',
  {
    listStyle: 'none',
  },
)

const MenuItem = system(
  {
    extend: Box,
    as: 'li',
    color: colors.white,
    fontFamily: fonts.main,
    fontSize: '15px',
    fontWeight: '400',
    pr: '15px',
    flex: '1 1 160px',
  },
  'flex',
  'space',
  'color',
  'fontWeight',
  'fontSize',
  'fontFamily',
  {
    letterSpacing: '0.075em',
    lineHeight: '1.6',
    a: {
      textDecoration: 'none',
      color: colors.white,
      fontWeight: '400',
    },
    'a:hover': {
      color: colors.lightTeal,
    },
  },
)

const ContactTitle = system(
  {
    extend: Text,
    fontSize: '12px',
    lineHeight: '2',
    fontWeight: '500',
    fontFamily: fonts.main,
    color: colors.white20,
  },
  {
    letterSpacing: '.2em',
    textTransform: 'uppercase',
  },
)

const Contact = system(
  {
    extend: Text,
    fontFamily: fonts.main,
    fontSize: '15px',
    fontWeight: '400',
    pr: '15px',
  },
  'space',
  'color',
  {
    letterSpacing: '0.075em',
    lineHeight: '1.6',
    a: {
      textDecoration: 'none',
      color: colors.white,
      fontWeight: '400',
    },
    'a:hover': {
      color: colors.lightTeal,
    },
  },
)

const Address = system(
  {
    extend: Box,
  },
  {
    a: {
      color: colors.white,
    },
    'a:hover': {
      color: colors.lightTeal,
    },
  },
)

const CopyrightContainer = system(
  {
    extend: Flex,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    p: '45px',
    bg: colors.footerBackground,
  },
  'space',
  'width',
  'color',
)

const Copyright = system(
  {
    extend: Text,
    color: colors.white20,
    fontFamily: fonts.main,
    fontSize: '15px',
  },
  'color',
  {
    letterSpacing: '.075em',
  },
)

export const Footer = () => {
  return (
    <Box>
      <ContentContainer pt="45px" bg={colors.darkTeal}>
        <Separator />
        <Container>
          <MenuContainer>
            <Title>
              <Link to="/about">ABOUT US</Link>
            </Title>
            <Menu maxWidth="280px">
              <MenuItem>
                Catamaran provides Construction, Fabrication, Turnaround, Maintenance and Repair
                services to customers primarily in the Oil & Gas, Chemical & Petrochemical, Pulp &
                Paper, and Power Generation industries.
              </MenuItem>
              <MenuItem>
                <Flex>
                  <div>
                    <a href="https://www.facebook.com/CatamaranSolutions/" target="_blank">
                      <img src={facebook} alt="Facebook" style={{ width: 30 }} />
                    </a>
                  </div>
                  <div>
                    <a href="https://www.linkedin.com/company/catamaransolutions" target="_blank">
                      <img src={linkedin} alt="LinkedIn" style={{ width: 30, marginLeft: 20 }} />
                    </a>
                  </div>
                </Flex>
              </MenuItem>
            </Menu>
          </MenuContainer>
          <MenuContainer>
            <Title>GET IN TOUCH</Title>
            <Menu maxWidth="270px">
              <MenuItem>
                <ContactTitle>Phone Number</ContactTitle>
                <Contact>
                  <Link to="tel:8444228262">(844) 4 - CATAMARAN</Link>
                </Contact>
              </MenuItem>
              <MenuItem>
                <ContactTitle>Address</ContactTitle>
                <Address>
                  <Link to="http://www.google.com/maps/place/142%20Old%20Shreveport%20Rd.Minden,%20LA%2071055">
                    <Contact>142 Old Shreveport Rd.</Contact>
                    <Contact>Minden, LA 71055</Contact>
                  </Link>
                </Address>
              </MenuItem>
              <MenuItem>
                <ContactTitle>Email</ContactTitle>
                <Contact>
                  <Link to="mailto:info@catamaransolutions.com">info@catamaransolutions.com</Link>
                </Contact>
              </MenuItem>
            </Menu>
          </MenuContainer>
          <MenuContainer>
            <Title>USEFUL LINKS</Title>
            <Menu maxWidth="320px">
              <Flex flexDirection={['column', 'row']}>
                <Box css={{ flex: 0.5 }}>
                  <MenuItem>
                    <Link to="/services/construction">Construction</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/services/fabrication">Fabrication</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/services/turnarounds">Turnarounds</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/services/maintenance">Maintenance</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/services/repairs">Repairs</Link>
                  </MenuItem>
                </Box>
                <Box css={{ flex: 0.5 }}>
                  <MenuItem>
                    <Link to="/about/leadership">Leadership</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/about/our-history">Our History</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/about/culture-and-values">Culture & Values</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/about/geographic-reach">Geographic Reach</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/about/industries-served">Industries Served</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/about/community-involvement">Community Involvement</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/careers">Careers</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/news">News</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </MenuItem>
                </Box>
              </Flex>
            </Menu>
          </MenuContainer>
        </Container>
      </ContentContainer>
      <CopyrightContainer>
        <Copyright>© 2020 Catamaran Solutions, LLC</Copyright>
      </CopyrightContainer>
    </Box>
  )
}
